<template>
  <div class="modal register-modal">
    <div class="overlay"  @click="$parent.closeRegister"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeRegister" src="./../assets/close.svg"/>
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="form-fields-wrapper">
                <div class="title">Create an account</div>
                <div class="desc">Login using your Email & Password</div>
                <div class="label-list">
                  <label>
                    <div class="desc">E-Mail Address</div>
                    <input type="email" v-model="email"/>
                  </label>
                  <label>
                    <div class="desc">Name</div>
                    <input type="text" v-model="name"/>
                  </label>
                  <label>
                    <div class="desc">Surname</div>
                    <input type="text" v-model="surname"/>
                  </label>
                  <label>
                    <div class="desc">Password</div>
                    <input type="password" v-model="pass"/>
                  </label>
                  <label>
                    <div class="desc">Password confirmation</div>
                    <input type="password" v-model="passConfirm"/>
                  </label>
                  
                  <div class="link-container">
                    <div class="chekbox-container">
                      <label class="chekbox-label">
                        <div class="chekbox-label-wrapper" v-if="$parent.textPageList.length">
                          <input type="checkbox" name="terms" v-model="terms"/>
                          <div class="checkbox"></div>
                          <span class="desc">I have read and agreed to the </span>
                          <a v-if="$parent.textPageList.filter(item => item.is_privacy)[0]" @click="$parent.goToPrivacy()" class="desc link"> {{ $parent.textPageList.filter(item => item.is_privacy)[0].title }}</a>
                          <span v-if="$parent.textPageList.filter(item => item.is_terms)[0]" class="desc"> and </span>
                          <a v-if="$parent.textPageList.filter(item => item.is_terms)[0]" @click="$parent.goToTerms()" class="desc link"> {{ $parent.textPageList.filter(item => item.is_terms)[0].title }}</a>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="button-container">
                  <button :class="['button', {'disabled': !requiredRegisterFieldsAreFilled}]" @click="submitRegister">
                    <span>Create Account</span>
                  </button>
                </div>
                <div class="desc">Already have an account?</div>
                <div class="desc link" @click="$parent.openSignInModal">Sign In!</div>
                <div v-if="$parent.error" class="desc red">{{$parent.error}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegisterModal',
  props: [],
  components: {
  },
  data: function() {
    return {
      email: '',
      name: '',
      surname: '',
      pass: '',
      passConfirm: '',
      terms: false,

    }
  },
  mounted() {
  },
  computed: {
    requiredRegisterFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.name && this.surname  && 
        this.email && this.pass
        && this.terms 
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    submitRegister() {
      let regData = {
        "name": this.name,
        "surname": this.surname,
        "email": this.email,
        "password": this.pass,
        "passConfirm": this.passConfirm
      }
        this.$emit('registration', regData)
    }
  }
}
</script>