<template>
	<footer class="footer">
		<div class="wrapper">
			<div class="flex">
				<div class="col">
					<router-link to="/" class="logo">
						<img src="./../assets/logo.svg" class="img"/>
					</router-link>
					<div class="desc">
						© 2024 ExCurr<br/>
						All Rights Reserved
					</div>
				</div>
				<div class="col">
					<div class="desc bold">Features</div>
					<ul class="nav">
						<template v-for="item in $parent.footerNav1">
							<li v-if="item.path" class="nav__item" :key="item.title">
								<router-link class="desc" :to="item.path">
									{{item.title}}
								</router-link>
							</li>
						</template>
					</ul>
				</div>
				<div class="col">
					<div class="desc bold">Support</div>
					<ul class="nav">
						<template  v-for="item in $parent.footerNav2">
							<li v-if="item.path" class="nav__item" :key="item.title">
								<router-link class="desc" :to="item.path">
									{{item.title}}
								</router-link>
							</li>
							<li v-if="item.modal == 'contact'" class="nav__item" :key="item.title">
								<div class="desc hover-link" @click="$parent.openContactModal">
									{{item.title}}
								</div>
							</li>
						</template>
					</ul>
				</div>
				<div class="col">
					<div class="desc bold">About</div>
					<ul class="nav">
						<template  v-for="item in $parent.textPageList">
							<li class="nav__item" :key="item.id">
								<a class="desc" @click="$parent.goToPage(item)">
								{{item.title}}
								</a>
							</li>
						</template>
					</ul>
				</div>
				<div class="col wrapper_social">
					<div class="social-links">
						<div v-for="link in $parent.socialLinks" :key="link.img" class="social_links__link">
						<a :href="link.link">
							<img :src="link.img" :alt="link.link"/>
						</a>
						</div>
					</div>
					<ul class="list">
						<li class="item" v-for="item in $parent.settings.payment_methods" :key="item.link">
							<img :src="item.image_full" class="img"/>
						</li>
					</ul>
				</div>
			</div>
			<div  v-if="$parent.legalInfo" class="desc copiryght">
				{{$parent.legalInfo}}
			</div>
		</div>
	</footer>
</template>

<script>

export default {
	name: 'Footer',
	props: [],
	data: function() {
		return {
			// legalInfo: '',
			// email: '',
			// phone: ''
		}
	},
	methods: {
		
	},
	computed: {
		hasCountryObject() {
		return this.$parent.textPageList.some(obj => obj.hasOwnProperty("is_countries") && obj.is_countries === 1);
		},
	},
	mounted() {
		// this.$http.get(process.env.VUE_APP_API + 'settings')
		// .then((res) => {
		// 	this.legalInfo = res.data.payload.legal_info;
		// 	this.email = res.data.payload.support_email;
		// 	this.phone = res.data.payload.support_phone;
		// })
		// .catch(() => {
		//
		// })
	}
}
</script>
