<template>
  <main class="main text-page">
    <div class="section text-section">
      <transition name="fade">
        <div class="wrapper" v-if="$parent.textPageTitle">
          <div class="title">{{$parent.textPageTitle}}</div>
          <div class="desc" v-html="$parent.textPageHeader"></div>
          <div class="desc" v-html="$parent.textPageContent"></div>
        </div>
      </transition>
    </div>
  </main>
</template>
<script>

export default {
  name: 'TextPage',
  props: [],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  mounted() {
    this.$parent.getTextPageContent(this.$route.params.id);
  },
  methods: {
    
  }
}
</script>