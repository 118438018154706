<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <img src="./../assets/decor/1.svg" class="decor1"/>
          <img src="./../assets/decor/2.png" class="decor2"/>
          <img src="./../assets/decor/3.png" class="decor3"/>
          <div class="title big">
            <b>Dive into the realm of cryptocurrency with ExCurr</b>
          </div>
          <div class="desc desc-main">Your exploration of the crypto universe is just one click away.</div>
          <button @click="checkAuth" class="button pink">Begin your adventure</button>
          <ul class="hero-features">
            <li class="item">
              <img src="./../assets/card-icon.svg" class="img"/>
              <div class="desc bold">Bank card Support</div>
            </li>
            <li class="item">
              <img src="./../assets/globe-icon.svg" class="img"/>
              <div class="desc bold">Supported in 70+ countries</div>
            </li>
            <li class="item">
              <img src="./../assets/discount-icon.svg" class="img"/>
              <div class="desc bold">Sell Orders</div>
            </li>
            <li class="item">
              <img src="./../assets/shield-icon.svg" class="img"/>
              <div class="desc bold">Bank grade Security</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="section exchange-section">
        <div class="wrapper">
          <img src="./../assets/decor/1.svg" class="decor1"/>
          <img src="./../assets/decor/2.png" class="decor2"/>
          <img src="./../assets/decor/3.png" class="decor3"/>
          <ExchangeCard @setAppAmounts="setAppAmounts" />
        </div>
      </div>
      <div class="section section-2">
        <div class="wrapper">
          <div class="card">
            <div class="flex">
              <div class="text">
                <div class="desc big">
                  Experience fast and easy verification to join the cryptocurrency mines even faster
                </div>
                <div class="title">
                  <b>Complete verification just in a few minutes</b>
                </div>
                <div class="desc">Take control of your cryptocurrency. Avoid the complicated steps of the verification process to join the cryptocurrency community even faster</div>
              </div>
              <div class="image">
                <img src="./../assets/secton2.png" class="img"/>
              </div>
            </div>
            <button class="button white" @click="$parent.openRegister">Create a profile</button>
          </div>
        </div>
      </div>
      <div class="section section-3">
        <div class="wrapper">
          <img src="./../assets/decor/4.png" class="decor4"/>
          <div class="flex">
            <div class="left">
              <div class="title">
                <b>Buy and sell crypto. All in one place.</b>
              </div>
              <div class="desc">
                Not sure where to start? We're here to help! Check out our frequently asked questions or contact our support team.
              </div>
              <div class="button-cotainer">
                <router-link to="/faq" class="button pink">FAQ’s</router-link>
                <a @click="$parent.openContactModal" class="desc link">Contact Support</a>
              </div>
            </div>
            <div class="right">
              <img src="./../assets/secton3.png" class="img"/>
              <div class="right-wrapper">
                <div class="desc big">
                  FAQ’s
                </div>
                <div class="desc big bold">
                  What is blockchain? How blockchain technology works
                </div>
                <div class="desc">
                  All your burning questions about blockchain technology. How does blockchain work and what problems does it solve? Find out in this easy guide.
                </div>
                <router-link to="/faq" class="desc link">Read more</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section section-4">
        <div class="title big title-main">
          <b>What we can offer you?</b>
        </div>
        <div class="wrapper">
          <img src="./../assets/decor/5.png" class="decor5"/>
          <div class="card">
            <div class="flex">
              <div class="left">
                <div class="title">
                  <b>Easy and understandable design</b>
                </div>
                <div class="desc">
                  We will make sure that the immersion into the world of cryptocurrency was not only interesting, but also simple and easy to understand!
                </div>
              </div>
              <div class="right">
                <img src="./../assets/secton4.png" class="img"/>
              </div>
            </div>
            <button class="button" @click="checkAuth">Get Started</button>
          </div>
          <div class="list">
            <div class="list__item">
              <img src="./../assets/secton4/1.png" class="img"/>
              <div class="desc big bold">
                Fast and secure transfers in a few clicks
              </div>
              <div class="desc">
                We will make sure that the immersion into the world of cryptocurrency was not only interesting, but also simple and easy to understand!
              </div>
            </div>
            <div class="list__item">
              <img src="./../assets/secton4/2.png" class="img"/>
              <div class="desc big bold">
                Bank card support
              </div>
              <div class="desc">
                Cryptocurrency transactions have never been easier - just use your bank card with no third-party intermediaries involved
              </div>
            </div>
            <div class="list__item">
              <img src="./../assets/secton4/3.png" class="img"/>
              <div class="desc big bold">
                Helpful support team
              </div>
              <div class="desc">
                From technical support to help in the world of cryptocurrency. Our support team is eager to help you!
              </div>
            </div>
          </div>
          <button class="button button-main" @click="$parent.goToPrivacy">Learn more about privacy & security</button>
        </div>
      </div>
      <div class="section-5 section">
        <div class="wrapper">
          <img src="./../assets/decor/6.png" class="decor6"/>
          <img src="./../assets/decor/7.png" class="decor7"/>
          <img src="./../assets/decor/8.png" class="decor8"/>
          <div class="title">
            <b>We know what you need and what we can do together</b>
          </div>
          <div class="desc">
            Excurr is developed by cryptocurrency enthusiasts who have come a long way in the world of cryptocurrency. We know how to make your journey easier, more enjoyable and exciting! Join our community and together, we can build something even bigger!
          </div>
          <button @click="checkAuth" class="button pink" >Begin your adventure</button>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

import ExchangeCard from "@/components/ExchangeCard.vue";
// import ContactForm from "@/components/ContactForm.vue";
// import ExchangeButton from "@/components/ExchangeButton.vue";

export default {
  name: 'Home',
  props: ['isAuth'],
  components: {
    // ExchangeButton,
    // ContactForm
    ExchangeCard
  },
  data: function() {
    return {
     
    }
  },
  mounted() {
    if (this.$route.query['redirect']) {
      this.$parent.openSignInModal();
    }
    
  },
  methods: {
    openSignInModal() {
      this.$emit('openSignInModal');
    },
    setAppAmounts(amounts) {
      this.$emit('setAppAmounts',  amounts)
    },
    checkAuth() {
      if (this.$parent.isAuth) {
        this.$router.push({path: '/buy-crypto'});
      } else {
        this.$parent.openSignInModal();
      }
    }
  }
}
</script>
