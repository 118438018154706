<template>
  <main class="main features-page">
    <div class="section features-section">
      <div class="wrapper">
        <img src="./../assets/decor/1.svg" class="decor1"/>
        <img src="./../assets/decor/2.png" class="decor2"/>
        <img src="./../assets/decor/3.png" class="decor3"/>
        <img src="./../assets/decor/5.png" class="decor4"/>
        <div class="title big">
          Features
        </div>
        <div class="list">
          <div class="item">
            <img src="./../assets/features/1.png" class="img"/>
            <div  class="desc bold big">Fast and safe transfers</div>
          </div>
          <div class="item">
            <img src="./../assets/features/2.png" class="img"/>
            <div  class="desc bold big">Fast and Easy Verification</div>
          </div>
          <div class="item">
            <img src="./../assets/features/3.png" class="img"/>
            <div  class="desc bold big">Bank-grade security</div>
          </div>
          <div class="item">
            <img src="./../assets/features/4.png" class="img"/>
            <div  class="desc bold big">Bank card support</div>
          </div>
          <div class="item">
            <img src="./../assets/features/5.png" class="img"/>
            <div  class="desc bold big">Helpful tech support</div>
          </div>
          <div class="item">
            <img src="./../assets/features/6.png" class="img"/>
            <div  class="desc bold big">Modern UI design</div>
          </div>
          <div class="item">
            <img src="./../assets/features/7.png" class="img"/>
            <div  class="desc bold big">Offline wallet for secure storagey</div>
          </div>
        </div>
      </div>
    </div>
    <div class="section exchange-section">
      <div class="wrapper">
        <img src="./../assets/decor/6.png" class="decor6"/>
        <img src="./../assets/decor/7.png" class="decor7"/>
        <img src="./../assets/decor/8.png" class="decor8"/>
        <div class="title">
          <b>Dive into the realm of cryptocurrency with ExCurr.</b>
        </div>
        <div class="desc">Your exploration of the crypto universe is just one click away. Begin your adventure.</div>
        <ExchangeCard @setAppAmounts="setAppAmounts"/>
      </div>
    </div>
  </main>
</template>
<script>
import ExchangeCard from "@/components/ExchangeCard.vue";

export default {
  name: 'Features',
  props: ['isAuth'],
  components: {
    ExchangeCard
  },
  data: function() {
    return {
    }
  },
  methods: {
    openSignInModal() {
      this.$emit('openSignInModal');
    },
    setAppAmounts(amounts) {
      this.$emit('setAppAmounts',  amounts)
    },
  }
}
</script>