<template>
  <div class="modal report-unavailable-modal">
    <div class="overlay" @click="$parent.closeReportUnavailableModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closeReportUnavailableModal" src="./../assets/close.svg"/>
      <div class="container">
        Report is unavailable at the moment
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportUnavailableModal',
  components: {},
  data: function () {
    return {}
  },
  mounted() {}
}
</script>