<template>
  <main class="main exchange-page">
    <div class="section exchange-section">
      <div class="wrapper">
        <img src="./../assets/decor/1.svg" class="decor1"/>
        <img src="./../assets/decor/2.png" class="decor2"/>
        <img src="./../assets/decor/3.png" class="decor3"/>
        <ExchangeCard @setAppAmounts="setAppAmounts" :type="'exchange'" />
        <ul class="hero-features">
          <li class="item">
            <img src="./../assets/card-icon.svg" class="img"/>
            <div class="desc bold">Bank card Support</div>
          </li>
          <li class="item">
            <img src="./../assets/globe-icon.svg" class="img"/>
            <div class="desc bold">Supported in 70+ countries</div>
          </li>
          <li class="item">
            <img src="./../assets/discount-icon.svg" class="img"/>
            <div class="desc bold">Sell Orders</div>
          </li>
          <li class="item">
            <img src="./../assets/shield-icon.svg" class="img"/>
            <div class="desc bold">Bank grade Security</div>
          </li>
        </ul>
      </div>
    </div>
  </main>
</template>
<script>
import ExchangeCard from "@/components/ExchangeCard.vue";

export default {
  name: 'ExchangeCrypto',
  props: ['isAuth'],
  components: {
    ExchangeCard
  },
  data: function() {
    return {
    }
  },
  watch: {
    
  },
  computed: {
   
  },
  methods: {
    openSignInModal() {
      this.$emit('openSignInModal');
    },
    setAppAmounts(amounts) {
      this.$emit('setAppAmounts',  amounts)
    },
  },
  mounted: function() {
  }
  
}
</script>