<template>
  <main class="main faq-page">
    <div class="section faq-section">
      <div class="wrapper">
        <img src="./../assets/decor/1.svg" class="decor1"/>
        <img src="./../assets/decor/2.png" class="decor2"/>
        <img src="./../assets/decor/5.png" class="decor4"/>
        <div class="title big">
          FAQ
        </div>
        <div class="flex">
          <div class="left">
            <div class="faq-questions">
              <div class="item" v-for="(item, index) in $parent.faq" :key="index">
                <div class="faq-question" @click="toggleAnswer(index)">
                  <div :class="['title small', {'active': isActiveAnswer(index)}]">
                    <b>{{ item.question }}</b>
                  </div>
                  <div v-show="isActiveAnswer(index)" class="desc big" v-html="item.answer" />
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <img src="./../assets/faq.svg" class="img"/>
            <div class="faq-answers">
              <div v-for="(item, index) in $parent.faq" :key="index" v-show="isActiveAnswer(index)" class="item faq-answer">
                <div class="desc big" v-html="item.question" />
                <div class="desc" v-html="item.answer" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section exchange-section">
      <div class="wrapper">
        <img src="./../assets/decor/6.png" class="decor6"/>
        <img src="./../assets/decor/7.png" class="decor7"/>
        <img src="./../assets/decor/8.png" class="decor8"/>
        <div class="title">
          <b>Dive into the realm of cryptocurrency with ExCurr.</b>
        </div>
        <div class="desc">Your exploration of the crypto universe is just one click away. Begin your adventure.</div>
        <ExchangeCard @setAppAmounts="setAppAmounts"/>
      </div>
    </div>
  </main>
</template>
<script>
import ExchangeCard from "@/components/ExchangeCard.vue";

export default {
  name: 'Faq',
  props: ['isAuth'],
  components: {
    ExchangeCard
  },
  data: function() {
    return {
      activeIndex: 0
    }
  },
  methods: {
    openSignInModal() {
      this.$emit('openSignInModal');
    },
    setAppAmounts(amounts) {
      this.$emit('setAppAmounts',  amounts)
    },
    toggleAnswer(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
    isActiveAnswer(index) {
      return this.activeIndex === index;
    }
  }
}
</script>