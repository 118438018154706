<template>
	<header class="header">
      <div class="wrapper">
        <div class="left">
			<router-link to="/" class="logo">
                <img src="./../assets/logo.svg" class="img"/>
            </router-link>
		</div>
		<div class="right">
			<ul class="nav">
				<template  v-for="item in $parent.headerNav">
					<li v-if="item.path" class="nav__item" :key="item.title">
						<router-link class="desc" :to="item.path">
							{{item.title}}
						</router-link>
					</li>
					<li v-if="item.modal == 'support'" class="nav__item" :key="item.title">
						<div class="desc hover-link" @click="$parent.openContactModal">
							{{item.title}}
						</div>
					</li>
				</template>
			</ul>
			<button v-if="!$parent.isAuth" class="button" @click="$parent.openSignInModal">Sign in</button>
			<div v-if="$parent.isAuth" class="user-link">
				<div class="user">
						<img src="./../assets/user.svg" class="img"/>
				</div>
				<div class="text" v-if="$parent.profileData.name">
					<div class="desc bold">{{ $parent.profileData.name }}</div>
					<div class="desc desc-status">{{ $parent.profileData.status == 'approved' ? 'Approved' : ' Not Approved' }}</div>
				</div>
				<div class="dropdown">
					<!-- <div class="warning">
						<img src="./../assets/fingerprint.svg" class="img"/>
						<div class="desc">Please verify your account</div>
					</div> -->
					<!-- <router-link to="/" class="button white">Verify</router-link> -->
					<div class="dropdown-nav">
						<div class="nav__item">
							<router-link class="desc" to="/profile">Profile</router-link>
						</div>
						<div class="nav__item">
							<router-link class="desc" to="/orders/buy">Orders</router-link>
						</div>
						<div class="nav__item">
							<a class="desc" @click="$parent.logout">Log out</a>
						</div>
					</div>
				</div>
			</div>
		</div>
      </div>
  </header>
</template>

<script>

export default {
	name: 'Header',
  props: [],
	data: function() {
		return {
		}
	},
	methods: {
    
	}
}
</script>
