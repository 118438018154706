<template>
  <main class="main order-details-page">
    <div class="section order-details-section">
      <div class="wrapper">
        <div class="content">
          <div class="step-list">
            <div :class="['desc bold', {'big': !Object.keys(paymentInfo).length && !orderApproved}]">1. Order details</div>
            <div :class="['desc bold', {'big': Object.keys(paymentInfo).length && !orderApproved}]">2. Payment</div>
            <div :class="['desc bold', {'big': orderApproved}]">3. Confirmation</div>
          </div>
          <div class="card-list">
            <template v-if="!$parent.checkoutSuccess">
            <div class="card-list__item order-details" v-if="!$parent.fiatToCrypto && walletStatus == 'denied'">
              <div class="desc big bold desc-main">Secure wallet verification</div>
              <div class="form">
                <label>
                  <div class="desc">Your wallet</div>
                  <input type="text" v-model="walletAddress"/>
                </label>
                <div class="desc desc-main desc-denied" v-if="walletStatus == 'denied' && walletAsset">Your wallet was denied, try another wallet</div>
              </div>
            </div>              
            <div class="card-list__item order-details" v-if="!$parent.fiatToCrypto && walletStatus == 'new'">
              <div class="desc big bold desc-main">Wallet verification is in progress</div>
              <div class="desc desc-status">Stay tuned for the completion of verification: {{ walletAddress }}</div>
            </div>
            <div class="card-list__item order-details" v-if="!$parent.fiatToCrypto && !$parent.fiatToCrypto && walletStatus == 'approved' && Object.keys(paymentInfo).length && !orderApproved">
              <div class="desc bold big desc-main">EXCHANGE {{ paymentInfo.asset.crypto_currency }} FOR SEPA {{ paymentInfo.asset.currency }}</div>
              <div class="form">
                <div class="desc bold desc-main">
                  How to send cryptocurrency:
                </div>
                <div class="desc desc-main desc-status">
                  To complete the creation of an exchange, you must:<br/>
                  01. Enter your cryptocurrency wallet to transfer funds<br/>
                  02. Make a transfer using the following details:<br/>
                  Wallet number: {{ paymentInfo.wallet }}<br/>
                  Transfer amount: {{ paymentInfo.amount }} {{ paymentInfo.asset.title }}
                </div>
                <div class="chekbox-container">
                  <label class="chekbox-label">
                    <div class="chekbox-label-wrapper">
                      <input type="checkbox" name="terms" v-model="terms"/>
                      <div class="checkbox"></div>
                      <span class="desc">I accept  </span>
                      <a @click="$parent.goToTerms()" class="desc link"> {{ $parent.textPageList.filter(item => item.is_terms)[0].title }}</a>
                      <span class="desc">  on exchange services</span>
                    </div>
                  </label>
                </div>
                <div class="desc desc-main">
                  Required! <br/>
                  Read the terms of the agreement on exchange services, when accepting it, please tick the appropriate field and press the button «I paid».
                </div>
                <div class="desc desc-main desc-status">
                  <b>Status:</b>
                  <div class="desc desc-main">
                    <b>Waiting For Payment Confirmation<br/>
                    Your Request Will Be Processed After The {{ paymentInfo.asset.title }} Are Credited To Our Wallet.</b>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-list__item order-details" v-if="orderApproved">
              <div class="desc-main bold desc big">Order accepted</div>
              <div class="form">
                <div class="desc desc-main desc-status">
                  Sender’s full name:	<br/>
                  {{ paymentInfo.first_name }} {{ paymentInfo.last_name }}
                </div>
                <div class="desc desc-main desc-status">
                  Beneficiary bank name:<br/>
                  {{ paymentInfo.bank_name }}
                </div>
                <div class="desc desc-main desc-status">
                  Bank account number (IBAN):	<br/>
                  {{ paymentInfo.bank_account }}
                </div>
                <div class="desc desc-main desc-status">
                  Wallet address:	<br/>
                  {{ paymentInfo.wallet }}
                </div>
                <div class="desc desc-main desc-status">
                  <b>Status:</b>
                  <div class="desc desc-main">
                    <b>Waiting For Payment Confirmation<br/>
                    Your Request Will Be Processed After The {{ paymentInfo.asset.title }} Are Credited To Our Wallet.</b>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-list__item order-details" v-if="!$parent.fiatToCrypto && walletStatus == 'approved' && !Object.keys(paymentInfo).length && !orderApproved">
              <div class="desc big bold desc-main">EXCHANGE {{ $parent.takeCurrency.title }} FOR {{ $parent.giveCurrency.title }}</div>
              <div class="form">
                <div class="desc bold desc-main">
                  Bank details for receiving payment:
                </div>
                <label>
                  <div class="desc">Recipient’s full name</div>
                  <input type="text" disabled v-model="recipientName"/>
                </label>
                <label>
                  <div class="desc">Beneficiary bank name</div>
                  <input type="text" v-model="beneficiaryBankName"/>
                </label>
                <label>
                  <div class="desc">Beneficiary bank address</div>
                  <input type="text" v-model="beneficiaryBankAddress"/>
                </label>
                <label>
                  <div class="desc">Bank account number (IBAN)</div>
                  <input type="text" v-model="iban"/>
                </label>
                <label>
                  <div class="desc">Wallet address</div>
                  <input type="text" disabled v-model="walletAddress"/>
                </label>
              </div>
            </div>
            <div class="card-list__item order-details" v-if="$parent.fiatToCrypto">
              <div class="desc big bold desc-main">Choose payment method</div>
              <div class="desc">Payment method</div>
              <div class="radio-list">
                <label>
                  <select 
                    class="select" 
                    v-if="paymenMethods" 
                    v-model="acivePaymentMethod"
                  >
                    <option v-for="item in paymenMethods" :key="item.id" :value="item">{{ item.title }}</option>
                  </select>
                  <img :src="acivePaymentMethod.image_full" class="img"/>
                </label>
                <!-- <div class="list">
                  <div class="item" v-if="!$parent.fiatToCrypto || ($parent.fiatToCrypto && paymentMethod == 'card')">
                    <label>
                      <input v-if="$parent.fiatToCrypto" type="radio" v-model="paymentMethod" name="paymentMethod" value="card"/>
                      <input v-if="!$parent.fiatToCrypto" type="radio" v-model="paymentMethod" name="paymentMethod" value="crypto"/>
                      <div class="text">
                        <svg v-if="$parent.fiatToCrypto" class="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.667 17.841">
                          <g id="Layer_1" transform="translate(-2.13 -5.896)">
                            <path id="Контур_12558" data-name="Контур 12558" d="M6.467,23.737h16A4.338,4.338,0,0,0,26.8,19.4V10.229A4.338,4.338,0,0,0,22.464,5.9h-16a4.338,4.338,0,0,0-4.333,4.333v1.544c0,.008,0,.014,0,.021s0,.014,0,.021V19.4a4.338,4.338,0,0,0,4.333,4.333Zm16-1.766h-16A2.57,2.57,0,0,1,3.9,19.4V12.678H25.031V19.4A2.57,2.57,0,0,1,22.464,21.972Zm-16-14.31h16a2.57,2.57,0,0,1,2.567,2.567v.683H3.9v-.683A2.57,2.57,0,0,1,6.467,7.662Z" transform="translate(0 0)" fill="#7f8798"/>
                            <path id="Контур_12559" data-name="Контур 12559" d="M10.865,20.469H6.737a.883.883,0,1,0,0,1.766h4.128a.883.883,0,0,0,0-1.766Z" transform="translate(-0.436 -1.707)" fill="#7f8798"/>
                          </g>
                        </svg>
                        <div class="desc">{{ $parent.fiatToCrypto ? 'Visa/Mastercard' : 'Crypto' }} </div>
                      </div>
                    </label>
                  </div>
                  <div v-if="$parent.fiatToCrypto && paymentMethod == 'eps'" class="item">
                    <label>
                      <input type="radio" v-model="paymentMethod" name="paymentMethod" value="eps"/>
                      <div class="text">
                        <img src="@/images/methods/eps.svg" class="img"/>
                        <div class="desc">Eps</div>
                      </div>
                    </label>
                  </div>
                  <div class="item" v-if="$parent.fiatToCrypto && paymentMethod == 'trustly'">
                    <label>
                      <input type="radio" v-model="paymentMethod" name="paymentMethod" value="trustly"/>
                      <div class="text">

                        <img src="@/images/methods/trustly.svg" class="img"/>
                        <div class="desc">Trustly</div>
                      </div>
                    </label>
                  </div>
                  <div class="item" v-if="$parent.fiatToCrypto && paymentMethod == 'giropay'">
                    <label>
                      <input type="radio" v-model="paymentMethod" name="paymentMethod" value="giropay"/>
                      <div class="text">
                        <img src="@/images/methods/giropay.svg" class="img"/>
                        <div class="desc">Giropay</div>
                      </div>
                    </label>
                  </div>
                  <div class="item" v-if="$parent.fiatToCrypto && paymentMethod == 'blik'">
                    <label>
                      <input type="radio" v-model="paymentMethod" name="paymentMethod" value="blik"/>
                      <div class="text">
                        <img src="@/images/methods/blik.svg" class="img"/>
                        <div class="desc">Blik</div>
                      </div>
                    </label>
                  </div>
                </div> -->
              </div>
              <!-- <div class="input-container">
                <div class="desc">Wallet:</div>
                <input type="text" v-model="wallet"/>
                <div class="clue">Please provide your wallet</div>
              </div> -->
              <div class="total">
                <div class="title small">
                  <b>Total amount to pay:</b>
                </div>
                <div class="total-wrapper">
                  <div class="item">
                    <div class="title" :class="{'crypto': !$parent.fiatToCrypto}">{{ leftAmount }} ≈</div>
                  </div>
                  <div class="item">
                    <div class="title" :class="{'crypto': $parent.fiatToCrypto}">{{ rightAmount }}</div>
                  </div>
                </div>
              </div>
              <div class="total-bottom">
                  <div class="text">
                    <div class="desc">Exchange rate</div>
                    <div class="desc">1 {{$parent.takeCurrency.crypto_currency}} = {{$parent.takeCurrency.price}} {{$parent.giveCurrency.symbol}}</div>
                  </div>
                 <div class="text">
                    <div class="desc">Date of order</div>
                    <div class="desc">{{currentDate}}</div>
                  </div>
              </div>
            </div>
            <div class="card-list__item-container">
              <div class="card-list__item your-card">
                <div class="title small title-main">Your Cart</div>
                <div class="row row-up">
                  <div class="title">{{ leftAmount }}</div>
                  <router-link to="/buy-crypto" class="button white">Change amount</router-link>
                </div>
                <div class="desc bold">{{ rightAmount }}</div>
                <div class="table">
                  <div class="title small">
                    <b>Order summary</b>
                  </div>
                  <div class="row" v-if="!paymentInfo">
                    <div class="desc">Exchange rate</div>
                    <div class="desc">1 {{$parent.takeCurrency.crypto_currency}} = {{$parent.takeCurrency.price}} {{$parent.giveCurrency.symbol}}</div>
                  </div>
                  <div class="row" v-if="paymentInfo && paymentInfo.asset && paymentInfo.currency_code">
                    <div class="desc">Exchange rate</div>
                    <div class="desc">1 {{paymentInfo.asset.crypto_currency}} = {{paymentInfo.fiatAmount}} {{paymentInfo.currency_code.symbol}}</div>
                  </div>
                  <div class="row">
                    <div class="desc">{{ $parent.fiatToCrypto ? 'Coins' : 'Money' }} to receive</div>
                    <div class="desc">{{ rightAmount }}</div>
                  </div>
                </div>
              </div>
            </div>
            </template>
            <template v-else>
              <div class="card-list__item order-success">
                <div class="order-submit-success">
                  <div class="order-submit-success__title">Order successfully submitted</div>
                  <div class="order-submit-success__message">
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="button-container" v-if="$parent.fiatToCrypto">
            <router-link to="/buy-crypto" class="button">Cancel order</router-link>
            <button class="button pink" @click="submit">Continue</button>
          </div>
          <div class="button-container" v-if="!$parent.fiatToCrypto && walletStatus == 'denied'">
            <router-link to="/sell-crypto" class="button">Cancel order</router-link>
            <button class="button pink" @click="verifyWallet">Send for verification</button>
          </div>
          <div class="button-container" v-if="!$parent.fiatToCrypto && walletStatus == 'approved' && !Object.keys(paymentInfo).length && !orderApproved">
            <router-link to="/sell-crypto" class="button">Cancel order</router-link>
            <button class="button pink" @click="sellCrypto">Create order</button>
          </div>
          <div class="button-container" v-if="!$parent.fiatToCrypto && walletStatus == 'approved' && Object.keys(paymentInfo).length && !orderApproved">
            <button :class="['button pink', {'disabled': !terms}]" @click="approveOrder">I paid</button>
          </div>
          <div class="button-container" v-if="orderApproved">
            <router-link to="/sell-crypto" class="button pink">Make new order</router-link>
          </div>
          <div v-if="error" class="desc red error">{{ error }}</div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

import fiatSection from "@/components/FiatSection.vue";

export default {
  name: 'OrderDetails',
  props: ['paymenMethods', 'profileData'],
  components: {
  },
  data: function() {
    return {
      paymentMethod: 'card',
      wallet: '',
      hours: 1,
      minutes: 0,
      seconds: 0,
      timer: null,
      currentDate: '',
      submitSuccess: false,
      acivePaymentMethod: {},
      recipientName: '',
      beneficiaryBankName: '',
      beneficiaryBankAddress: '',
      iban: '',
      walletAddress: '',
      walletStatus: 'denied',
      error: '',
      paymentInfo: {},
      orderApproved: false,
      terms:'',
      walletAsset: ''
    }
  },
  watch: {
    paymenMethods: {
      immediate: true,
      handler(newValue) {
        if(newValue && newValue[0]) {
         this.acivePaymentMethod = newValue[0]
        }
      },
    },
    profileData: {
      immediate: true,
      handler(newValue) {
        this.recipientName = newValue.name + ' ' + newValue.surname 
      },
    },
    
  },
  
  mounted: function() {
    if (this.$parent.paymentMethod) {
      this.paymentMethod = this.$parent.paymentMethod
    }
    if (!this.$parent.giveAmount && this.$route.params.type == 'buy') {
      this.$router.push({path: '/buy-crypto'})
    } else if(!this.$parent.giveAmount && this.$route.params.type == 'sell') {
      this.$emit('setFiatToCrypto', false)
      if (localStorage.getItem('paymentInfo')) {
        this.getWalletStatus();
      } else {
        this.$router.push({path: '/sell-crypto'})
      }
    } else if (this.$parent.giveAmount && this.$route.params.type == 'sell') {
      this.$emit('setFiatToCrypto', false)
      this.getWalletStatus();
    }
    


    this.$parent.changeCheckoutSuccess(false);
    this.startTimer();
    this.getCurrentDate();
  },
  methods: {
    approveOrder() {
      this.orderApproved = true;
      localStorage.setItem('paymentInfo', '');
    },
    verifyWallet() {
      let wallet = {
        wallet: this.walletAddress,
        asset: this.$parent.takeCurrency.title
      }
      this.$http.post(process.env.VUE_APP_API + 'sell/sell_wallet_verification', wallet)
      .then((res) => {
        if (res.data.status == 'OK') {
          this.error = '';
          this.getWalletStatus();
        }
      })
      .catch((res) => {
        if (res.response.data.errors) {
          const firstErrors = {};
          for (const key in res.response.data.errors) {
            if (res.response.data.errors.hasOwnProperty(key) && Array.isArray(res.response.data.errors[key]) && res.response.data.errors[key].length > 0) {
                firstErrors[key] = res.response.data.errors[key][0];
                this.error = firstErrors[key];
            }
          }
        } else {
          this.error = res.response.data.message;
        }
      }) 
    },
    sellCrypto() {
      let storage = {
        'amount': this.$parent.takeAmount,
        'wallet': this.walletAddress,
        'asset': this.$parent.takeCurrency,
        'currency_code': this.$parent.giveCurrency,
        'bank_account': this.iban,
        'bank_name': this.beneficiaryBankName,
        'bank_address': this.beneficiaryBankAddress,
        'first_name': this.profileData.name,
        'last_name': this.profileData.surname,
        'address': this.profileData.address,
        'fiatAmount': this.$parent.giveAmount
      }

      let data = {
        'amount': this.$parent.takeAmount,
        'wallet': this.walletAddress,
        'asset': this.$parent.takeCurrency.crypto_currency,
        'currency_code': this.$parent.giveCurrency.title == 'Euro' ? 'EUR' : '',
        'bank_account': this.iban,
        'bank_name': this.beneficiaryBankName,
        'bank_address': this.beneficiaryBankAddress,
        'first_name': this.profileData.name,
        'last_name': this.profileData.surname,
        'address': this.profileData.address,
      }
      this.$http.post(process.env.VUE_APP_API + 'sell/create', data)
      .then(() => {
        this.paymentInfo = storage;
        localStorage.setItem('paymentInfo', JSON.stringify(storage));
      })
      .catch((res) => {
        if (res.response.data.errors) {
          const firstErrors = {};
          for (const key in res.response.data.errors) {
            if (res.response.data.errors.hasOwnProperty(key) && Array.isArray(res.response.data.errors[key]) && res.response.data.errors[key].length > 0) {
                firstErrors[key] = res.response.data.errors[key][0];
                this.error = firstErrors[key];
            }
          }
        } else {
          this.error = res.response.data.message;
        }
      })
    },
    getWalletStatus() {
      this.$http.get(process.env.VUE_APP_API + 'sell/sell_wallet_verification_status?asset=' + this.$parent.takeCurrency.crypto_currency)
      .then((res) => {
        this.walletStatus = res.data.order.status;
        this.walletAddress = res.data.order.wallet;
        this.walletAsset = res.data.order.asset;
        if (this.walletStatus == 'approved' && localStorage.getItem('paymentInfo')) {
          this.paymentInfo =  JSON.parse(localStorage.getItem('paymentInfo'));
        }
      })
      .catch(() => {
      })
    },
    getCurrentDate() {
      const today = new Date();
      const formattedDate = today.toISOString().split('T')[0];

      this.currentDate = formattedDate;
    },
   startTimer() {
      this.timer = setInterval(() => {
        if (this.hours === 0 && this.minutes === 0 && this.seconds === 0) {
          clearInterval(this.timer);
        } else {
          if (this.seconds > 0) {
            this.seconds--;
          } else {
            if (this.minutes > 0) {
              this.minutes--;
              this.seconds = 59;
            } else {
              this.hours--;
              this.minutes = 59;
              this.seconds = 59;
            }
          }
        }
      }, 1000);
    },
    submit() {
      this.$parent.openPersonalInfoModal();
      this.$emit('setWallet', this.wallet);
      this.$emit('setPaymentMethod', this.paymentMethod);
    }
  },
  computed: {
    fiatSection() {
      return fiatSection
    },
    leftAmount()  {
      if (localStorage.getItem('paymentInfo')) {
        return JSON.parse(localStorage.getItem('paymentInfo')).amount + ' ' +  JSON.parse(localStorage.getItem('paymentInfo')).asset.crypto_currency;
      } else {
        const parent = this.$parent;
        return parent.fiatToCrypto ? `${parent.giveCurrency.symbol} ${parseFloat(parent.giveAmount ).toFixed(2)}` : `${parent.takeAmount} ${parent.takeCurrency.crypto_currency}`;
      }
    },
    rightAmount() {
      if (localStorage.getItem('paymentInfo')) {
        return JSON.parse(localStorage.getItem('paymentInfo')).currency_code.symbol + ' ' + JSON.parse(localStorage.getItem('paymentInfo')).fiatAmount;
      } else {
        const parent = this.$parent;
        return parent.fiatToCrypto ? `${parent.takeAmount} ${parent.takeCurrency.crypto_currency}` : `${parent.giveCurrency.symbol} ${parseFloat(parent.giveAmount ).toFixed(2)}`;
      }
    }
  }
}
</script>