<template>
  <div class="exchange-card card">
    <div class="card-top">
      <div class="title">
        <b v-if="cryptoFormState == 'exchange'">Crypto Exchange</b>
        <b v-if="cryptoFormState == 'buy'">Buy Orders</b>
        <b v-if="cryptoFormState == 'sell'">Sell orders</b>
      </div>
      <div 
        :class="['desc link hover-link', {'white': cryptoFormState == 'exchange'}]" 
        @click="changeCryptoFormState('exchange')"
      >
        Exchange
      </div>
      <div 
        :class="['desc link hover-link', {'white': cryptoFormState == 'buy'}]" 
        @click="changeCryptoFormState('buy')"
      >
        Buy
      </div>
      <div 
        :class="['desc link hover-link', {'white': cryptoFormState == 'sell'}]" 
        @click="changeCryptoFormState('sell')"
      >
        Sell
      </div>
    </div>
    <div class="card-middle">
      <div class="flex">
        <div class="select-container" v-if="cryptoFormState == 'sell'">
          <div class="desc">CRYPTO</div>
          <div class="select-wrapper" v-if="chosenCrypto">
            <label>
              <img v-if="chosenCrypto.image" class="img" :src="chosenCrypto.image" />
              <select 
                class="currency-select" 
                v-if="exchangeList" 
                v-model="chosenCrypto"
                @change="changeTakeAmount(fiatAmount)"
              >
                <option v-for="item in exchangeList" :key="item.id" :value="item">{{ item.title }}</option>
              </select>
            </label>
            <input class="input" v-model="cryptoAmount" @input="changeGiveAmount($event.target.value, true);"/>
          </div>
        </div>

        <div class="select-container" v-if="cryptoFormState == 'buy' || cryptoFormState == 'exchange'">
          <div class="desc">FIAT</div>
          <div class="select-wrapper" v-if="chosenFiat">
            <label>
              <img v-if="chosenFiat.image" class="img" :src="chosenFiat.image" />
              <select 
                class="currency-select" 
                v-if="fiatList"
                v-model="chosenFiat"
              >
                <option v-for="item in fiatList" :key="item.id" :value="item">{{ item.title }}</option>
              </select>
            </label>
            <input class="input" v-model="fiatAmount" @input="changeTakeAmount($event.target.value);"/>
          </div>
        </div>

        <div class="swap">
          <img v-if="cryptoFormState == 'exchange'" src="./../assets/swap.svg" class="img exchange-img"/>
          <img v-else src="./../assets/swap2.svg" class="img"/>
        </div>

        <div class="select-container" v-if="cryptoFormState == 'sell'">
          <div class="desc">FIAT</div>
          <div class="select-wrapper" v-if="chosenFiat">
            <label>
              <img v-if="chosenFiat.image" class="img" :src="chosenFiat.image" />
              <select 
                class="currency-select" 
                v-if="fiatList" 
                v-model="chosenFiat"
              >
                <option v-for="item in fiatList" :key="item.id" :value="item">{{ item.title }}</option>
              </select>
            </label>
            <input class="input" disabled v-model="fiatAmount" @input="changeTakeAmount($event.target.value);"/>
          </div>
        </div>

        <div class="select-container" v-if="cryptoFormState == 'buy' || cryptoFormState == 'exchange'">
          <div class="desc">CRYPTO</div>
          <div class="select-wrapper" v-if="chosenCrypto">
            <label>
              <img v-if="chosenCrypto.image" class="img" :src="chosenCrypto.image" />
              <select 
                class="currency-select" 
                v-if="exchangeList"
                v-model="chosenCrypto"
                @change="changeTakeAmount(fiatAmount)"
              >
                <option v-for="item in exchangeList" :key="item.id" :value="item">{{ item.title }}</option>
              </select>
            </label>
            <input class="input" disabled v-model="cryptoAmount" />
          </div>
        </div>

        <button v-if="cryptoFormState == 'sell'" @click="submit(0)" class="button">Sell Crypto</button>
        <button v-else @click="submit(0)" class="button">Buy Crypto</button>
      </div>
      <div class="desc desc-info" v-if="cryptoFormState != 'sell'">Minimum deposit amount - 25 EUR</div>
    </div>

    <div class="card-bottom">
      <div class="desc big" v-if="cryptoFormState == 'exchange'">
        Profitable exchange rate:
      </div>
      <div class="main-title-container" v-if="cryptoFormState == 'buy'">
        <div class="desc big">
          Buy bitcoin with profitable exchange rate:
        </div>
        <router-link to="/orders/buy" class="desc link hover-link">
          <span>Your orders</span>
          <img src="./../assets/orders.svg" class="img"/>
        </router-link>
      </div>
      <div class="main-title-container" v-if="cryptoFormState == 'sell'">
        <div class="desc big">
          Sell bitcoin with profitable exchange rate:
        </div>
        <router-link to="/orders/sell" class="desc link hover-link">
          <span>Your orders</span>
          <img src="./../assets/orders.svg" class="img"/>
        </router-link>
      </div>
      <div class="list list-2" v-if="cryptoFormState == 'buy'">
        <div class="item">
          <div class="desc big bold">350 €</div>
          <div class="text" v-if="fiatList['EUR'] || exchangeList.length">
            <div class="desc">{{btcOfEur(350)}} BTC</div>
            <div class="desc link bold hover-link" @click="submit(350)">Buy Now</div>
          </div>
        </div>
        <div class="item">
          <div class="desc big bold">500 €</div>
          <div class="text" v-if="fiatList['EUR'] || exchangeList.length">
            <div class="desc">{{btcOfEur(500)}} BTC</div>
            <div class="desc link bold hover-link" @click="submit(500)">Buy Now</div>
          </div>
        </div>
        <div class="item">
          <div class="desc big bold">1000 €</div>
          <div class="text" v-if="fiatList['EUR'] || exchangeList.length">
            <div class="desc">{{btcOfEur(1000)}} BTC</div>
            <div class="desc link bold hover-link" @click="submit(1000)">Buy Now</div>
          </div>
        </div>
        <div class="item">
          <div class="desc big bold">5000 €</div>
          <div class="text" v-if="fiatList['EUR'] || exchangeList.length">
            <div class="desc">{{btcOfEur(5000)}} BTC</div>
            <div class="desc link bold hover-link" @click="submit(5000)">Buy Now</div>
          </div>
        </div>
      </div>
      <div class="list list-2" v-if="cryptoFormState == 'sell'">
        <div class="item">
          <div class="desc big bold">350 €</div>
          <div class="text" v-if="fiatList['EUR'] || exchangeList.length">
            <div class="desc">{{btcOfEur(350)}} BTC</div>
            <div class="desc link bold hover-link" @click="submit(350)">Buy Now</div>
          </div>
        </div>
        <div class="item">
          <div class="desc big bold">500 €</div>
          <div class="text" v-if="fiatList['EUR'] || exchangeList.length">
            <div class="desc">{{btcOfEur(500)}} BTC</div>
            <div class="desc link bold hover-link" @click="submit(500)">Buy Now</div>
          </div>
        </div>
        <div class="item">
          <div class="desc big bold">1000 €</div>
          <div class="text" v-if="fiatList['EUR'] || exchangeList.length">
            <div class="desc">{{btcOfEur(1000)}} BTC</div>
            <div class="desc link bold hover-link" @click="submit(1000)">Buy Now</div>
          </div>
        </div>
        <div class="item">
          <div class="desc big bold">5000 €</div>
          <div class="text" v-if="fiatList['EUR'] || exchangeList.length">
            <div class="desc">{{btcOfEur(5000)}} BTC</div>
            <div class="desc link bold hover-link" @click="submit(5000)">Buy Now</div>
          </div>
        </div>
      </div>
      <div class="list" v-if="cryptoFormState == 'exchange'">
        <div class="item">
          <div class="desc">Yesterday</div>
          <div class="text">
            <div class="desc">
              <b>€ 61830.00</b>
            </div>
            <div class="desc">0.74%</div>
            <img src="./../assets/up.svg" class="img"/>
          </div>
        </div>
        <div class="item">
          <div class="desc">Last Week</div>
          <div class="text">
            <div class="desc">
              <b>€ 61830.00</b>
            </div>
            <div class="desc">0.74%</div>
            <img src="./../assets/up.svg" class="img"/>
          </div>
        </div>
        <div class="item">
          <div class="desc">Last Month</div>
          <div class="text">
            <div class="desc">
              <b>€ 61830.00</b>
            </div>
            <div class="desc">0.74%</div>
            <img src="./../assets/up.svg" class="img"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Decimal from "decimal.js";

export default {
  props: ['type'],
  data() {
    return {
      fiatAmount: '100',
      cryptoAmount: '0,00413',
      cryptoFormState: 'sell',
      exchangeList: [],
      fiatList: [],
      giveAmount: 300,
      takeAmount: 0,
      chosenFiat: {},
      chosenCrypto: {}
    };
  },
  mounted() {
    this.getExchangeList();
    if (this.type) {
      this.cryptoFormState = this.type;
    }
  },
  methods: {
    changeCryptoFormState(formState) {
      this.cryptoFormState = formState;

      if (this.chosenCrypto && this.chosenCrypto.crypto_currency === 'btc') {
        this.changeTakeAmount(this.giveAmount, true);
      }
    },
    getExchangeList() {
      this.$http.get(process.env.VUE_APP_API + 'exchange-top')
          .then((res) => {
            this.exchangeList = res.data.payload;
            this.chosenCrypto = res.data.payload[0]
            this.changeTakeAmount(this.giveAmount, true);
          })
          .catch(() => {
          })
      this.$http.get(process.env.VUE_APP_API + 'currencies-data')
          .then((res) => {
            this.fiatList = res.data.currencies;
            this.chosenFiat = res.data.currencies['EUR']
          })
          .catch(() => {
          })

    },
    changeGiveAmount(newValue, update = false) {
      this.fiatAmount = this.calculateCryptoPrice(this.chosenCrypto).mul(newValue).toFixed(2);

      if (update) {
        this.changeTakeAmount(this.fiatAmount, false);
      }
    },
    changeTakeAmount(newValue, update = false) {
      this.cryptoAmount = new Decimal(newValue).div(this.calculateCryptoPrice(this.chosenCrypto)).toString();

      if (update) {
        this.changeGiveAmount(this.cryptoAmount, false);
      }
    },
    submit(precise) {
      if (this.$parent.isAuth) {
        let amounts;
        if (precise) {
          amounts = {
            'fiat': precise,
            'crypto': this.btcOfEur(precise),
            'fiatCurr': this.chosenFiat,
            'cryptoCurr': {
              ...this.exchangeList[0],
              price: this.calculateCryptoPrice(this.exchangeList[0])
            },
            'type': this.cryptoFormState,
          }
        } else {
          amounts = {
            'fiat': this.fiatAmount,
            'crypto': this.cryptoAmount,
            'fiatCurr': this.chosenFiat,
            'cryptoCurr': {
              ...this.chosenCrypto,
              price: this.calculateCryptoPrice(this.chosenCrypto)
            },
            'type': this.cryptoFormState,
          }
        }
        this.$emit('setAppAmounts', amounts)
      } else {
        this.$parent.openSignInModal();
      }
    },
    calculateCryptoPrice(crypto) {
      const price = new Decimal(crypto.price);

      if ((this.cryptoFormState == 'buy' || this.cryptoFormState == 'exchange') && crypto.crypto_currency === 'btc') {
        return (price.add(price.mul(0.1)).add(price.mul(0.0075)))
      }

      return price;
    },
    btcOfEur(val) {
      let newVal;
      if (val) {
        newVal = new Decimal(val).div(this.calculateCryptoPrice(this.chosenCrypto)).toFixed(7).toString();
      } else {
        newVal = '';
      }
      return newVal;
    }
  },
  computed: {
   
  }
}
</script>
  